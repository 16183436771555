import React, { useState } from 'react';
import './Store.css'; // Ensure this contains the necessary CSS
import Modal from './Modal'; // Assuming you have a Modal component
import welcomekit from '../assets/welkmkit.png';
import wel2 from '../assets/wel2.png';
import bottle from '../assets/botle.png';
import bottle2 from '../assets/botle2.png';
import farmerImage from '../assets/farmer.png';
import c1 from '../assets/c1.png';
import c2 from '../assets/c2.png';
import c3 from '../assets/c3.png';
import c4 from '../assets/c4.png';
import c5 from '../assets/n1.png';
import c6 from '../assets/n2.png';
import c7 from '../assets/n3.png';

const products = [
  { id: 1, name: 'Welcome Kit', image: welcomekit, description: 'A comprehensive kit containing essential bamboo products to get you started on your sustainable journey.', price: '1200 Rs', category: 'kits' },
  { id: 2, name: 'Bamboo Mug', image: wel2, description: 'A stylish bamboo mug perfect for your hot beverages, combining functionality with eco-friendliness.', price: '1500 Rs', category: 'mugs' },
  { id: 3, name: 'Bamboo Water Bottle', image: bottle, description: 'A durable bamboo water bottle that keeps your drinks cool and helps reduce plastic waste.', price: '100 Rs', category: 'bottles' },
  { id: 4, name: 'Bamboo Flask', image: bottle2, description: 'A sleek bamboo flask designed for keeping your beverages hot or cold while being environmentally conscious.', price: '100 Rs', category: 'flasks' },
  { id: 5, name: 'Bamboo Notebook', image: c1, description: 'A stylish bamboo notebook for jotting down your thoughts, ideas, or notes with a touch of nature.', price: '150 Rs', category: 'notebooks' },
  { id: 6, name: 'Bamboo Pen Set', image: c2, description: 'An elegant set of bamboo pens that add a touch of sophistication to your writing experience.', price: '150 Rs', category: 'pens' },
  { id: 7, name: 'Bamboo Coasters', image: c3, description: 'A set of bamboo coasters that protect your surfaces and add a natural touch to your decor.', price: '150 Rs', category: 'coasters' },
  { id: 8, name: 'Bamboo Phone Stand', image: c4, description: 'A handy bamboo phone stand that provides a convenient way to keep your phone in view while you work or relax.', price: '150 Rs', category: 'phone stands' },
  { id: 9, name: 'Bamboo Phone Stand', image: c5, description: 'A handy bamboo phone stand that provides a convenient way to keep your phone in view while you work or relax.', price: '150 Rs', category: 'phone stands' },
  { id: 10, name: 'Bamboo Phone Stand', image: c6, description: 'A handy bamboo phone stand that provides a convenient way to keep your phone in view while you work or relax.', price: '150 Rs', category: 'phone stands' },
  { id: 11, name: 'Bamboo Phone Stand', image: c7, description: 'A handy bamboo phone stand that provides a convenient way to keep your phone in view while you work or relax.', price: '150 Rs', category: 'phone stands' },
];

function Store() {
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedCategory, setSelectedCategory] = useState('all'); // State for category

  const openModal = (product) => {
    setSelectedProduct(product);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
    setSelectedProduct(null);
  };

  // Filter products based on the selected category
  const filteredProducts = selectedCategory === 'all'
    ? products
    : products.filter(product => product.category === selectedCategory);

  return (
    <div className="store-page">
      {/* Farmer Image Banner */}
      <div className="banner">
        <img src={farmerImage} alt="Farmer supporting bamboo products" className="farmer-image" />
        <div className="banner-text">
          <h1>Support Our Farmers, Support Bamboo Products</h1>
          <p>Every purchase helps our farmers and promotes sustainable, eco-friendly products.</p>
        </div>
      </div>

      <h1>Welcome to Our Store</h1>
      <p>Explore our products and make your choice!</p>

      {/* Category Filter */}
      <div className="category-filter">
        <button onClick={() => setSelectedCategory('all')} className={selectedCategory === 'all' ? 'active' : ''}>All</button>
        <button onClick={() => setSelectedCategory('kits')} className={selectedCategory === 'kits' ? 'active' : ''}>Kits</button>
        <button onClick={() => setSelectedCategory('mugs')} className={selectedCategory === 'mugs' ? 'active' : ''}>Mugs</button>
        <button onClick={() => setSelectedCategory('bottles')} className={selectedCategory === 'bottles' ? 'active' : ''}>Bottles</button>
        <button onClick={() => setSelectedCategory('flasks')} className={selectedCategory === 'flasks' ? 'active' : ''}>Flasks</button>
        <button onClick={() => setSelectedCategory('notebooks')} className={selectedCategory === 'notebooks' ? 'active' : ''}>Notebooks</button>
        <button onClick={() => setSelectedCategory('pens')} className={selectedCategory === 'pens' ? 'active' : ''}>Pens</button>
        <button onClick={() => setSelectedCategory('coasters')} className={selectedCategory === 'coasters' ? 'active' : ''}>Coasters</button>
        <button onClick={() => setSelectedCategory('phone stands')} className={selectedCategory === 'phone stands' ? 'active' : ''}>Phone Stands</button>
      </div>

      <div className="product-grid">
        {filteredProducts.map((product) => (
          <div key={product.id} className="product-card">
            <img src={product.image} alt={product.name} className="product-image" />
            <h2>{product.name}</h2>
            <p>{product.description}</p>
            <p className="price">{product.price}</p>
            <button className="buy-button" onClick={() => openModal(product)}>Buy Now</button>
          </div>
        ))}
      </div>

      {modalOpen && (
        <Modal product={selectedProduct} closeModal={closeModal} />
      )}
    </div>
  );
}

export default Store;


