const questions = [
    // Certificate Course in Structured Query Language using MySQL
    {
      id: 1,
      subject: "Structured Query Language (SQL) using MySQL",
      question: "Which command is used to retrieve data from a database?",
      options: ["SELECT", "INSERT", "UPDATE", "DELETE"],
      answer: "SELECT"
    },
    {
      id: 2,
      subject: "Structured Query Language (SQL) using MySQL",
      question: "What type of join returns all records when there is a match in either left or right table?",
      options: ["INNER JOIN", "LEFT JOIN", "RIGHT JOIN", "FULL JOIN"],
      answer: "FULL JOIN"
    },
    {
      id: 3,
      subject: "Structured Query Language (SQL) using MySQL",
      question: "Which keyword is used to sort the result-set in SQL?",
      options: ["ORDER BY", "SORT", "GROUP BY", "FILTER"],
      answer: "ORDER BY"
    },
    {
      id: 4,
      subject: "Structured Query Language (SQL) using MySQL",
      question: "What is the default port number for MySQL?",
      options: ["8080", "3306", "1433", "1521"],
      answer: "3306"
    },
    {
      id: 5,
      subject: "Structured Query Language (SQL) using MySQL",
      question: "Which function is used to calculate the number of rows in a table?",
      options: ["COUNT()", "SUM()", "TOTAL()", "NUMBER()"],
      answer: "COUNT()"
    },
  
    // Introduction to Python
    {
      id: 6,
      subject: "Introduction to Python",
      question: "Which of the following is a correct way to start a Python comment?",
      options: ["//", "#", "/*", "<!--"],
      answer: "#"
    },
    {
      id: 7,
      subject: "Introduction to Python",
      question: "Which Python keyword is used to define a function?",
      options: ["func", "def", "define", "lambda"],
      answer: "def"
    },
    {
      id: 8,
      subject: "Introduction to Python",
      question: "What is the output of the expression '5' + '5'?",
      options: ["10", "'10'", "'55'", "Error"],
      answer: "'55'"
    },
    {
      id: 9,
      subject: "Introduction to Python",
      question: "What data type is the result of 3 / 2 in Python 3?",
      options: ["Integer", "Float", "String", "Complex"],
      answer: "Float"
    },
    {
      id: 10,
      subject: "Introduction to Python",
      question: "What is used to install external libraries in Python?",
      options: ["install", "pip", "get", "fetch"],
      answer: "pip"
    },
  
    // Certificate Course in Basic Python
    {
      id: 11,
      subject: "Basic Python",
      question: "Which Python data type is used to store multiple items in a single variable?",
      options: ["List", "Dictionary", "Tuple", "Array"],
      answer: "List"
    },
    {
      id: 12,
      subject: "Basic Python",
      question: "How do you insert an item at index 2 in a Python list named 'myList'?",
      options: ["myList.add(2, item)", "myList.insert(2, item)", "myList[2] = item", "myList.put(2, item)"],
      answer: "myList.insert(2, item)"
    },
    {
      id: 13,
      subject: "Basic Python",
      question: "Which operator is used for exponentiation in Python?",
      options: ["^", "**", "exp", "pow"],
      answer: "**"
    },
    {
      id: 14,
      subject: "Basic Python",
      question: "What does the len() function do in Python?",
      options: ["Calculates the sum of a list", "Returns the length of an object", "Concatenates strings", "Finds the largest number"],
      answer: "Returns the length of an object"
    },
    {
      id: 15,
      subject: "Basic Python",
      question: "What is the purpose of the 'self' keyword in Python classes?",
      options: ["It represents the class itself", "It binds the method to the class", "It represents an instance of the class", "It initializes the class"],
      answer: "It represents an instance of the class"
    },
  
    // Advanced Program in Cyber Security
    {
      id: 16,
      subject: "Cyber Security",
      question: "What does 'phishing' refer to in cyber security?",
      options: ["A network attack", "A type of malware", "Deceptive emails attempting to steal information", "A secure encryption method"],
      answer: "Deceptive emails attempting to steal information"
    },
    {
      id: 17,
      subject: "Cyber Security",
      question: "What is the function of a firewall in network security?",
      options: ["To encrypt data", "To monitor network traffic", "To block unauthorized access", "To compress files"],
      answer: "To block unauthorized access"
    },
    {
      id: 18,
      subject: "Cyber Security",
      question: "Which cyber attack involves overwhelming a server with requests?",
      options: ["Man-in-the-Middle", "Phishing", "Denial of Service (DoS)", "SQL Injection"],
      answer: "Denial of Service (DoS)"
    },
    {
      id: 19,
      subject: "Cyber Security",
      question: "What is multi-factor authentication (MFA)?",
      options: ["A backup method for passwords", "A single-step login process", "An authentication process with multiple steps", "A type of encryption"],
      answer: "An authentication process with multiple steps"
    },
    {
      id: 20,
      subject: "Cyber Security",
      question: "Which is a strong password practice?",
      options: ["Using a single word", "Using personal information", "Combining letters, numbers, and symbols", "Using only lowercase letters"],
      answer: "Combining letters, numbers, and symbols"
    },
  
    // Certificate Course in Linux Server Administration
    {
      id: 21,
      subject: "Linux Server Administration",
      question: "Which command is used to list files in Linux?",
      options: ["ls", "dir", "list", "show"],
      answer: "ls"
    },
    {
      id: 22,
      subject: "Linux Server Administration",
      question: "Which command is used to change directories in Linux?",
      options: ["cd", "chdir", "move", "change"],
      answer: "cd"
    },
    {
      id: 23,
      subject: "Linux Server Administration",
      question: "What is the purpose of the sudo command in Linux?",
      options: ["To display system information", "To switch user accounts", "To execute a command as superuser", "To start a service"],
      answer: "To execute a command as superuser"
    },
    {
      id: 24,
      subject: "Linux Server Administration",
      question: "What is the default shell in most Linux distributions?",
      options: ["zsh", "fish", "bash", "cmd"],
      answer: "bash"
    },
    {
      id: 25,
      subject: "Linux Server Administration",
      question: "Which directory contains system log files in Linux?",
      options: ["/home", "/log", "/var/log", "/usr"],
      answer: "/var/log"
    },
  
    // Certificate Course in Cyber Security (Additional)
    {
      id: 26,
      subject: "Cyber Security",
      question: "What is the purpose of encryption in cybersecurity?",
      options: ["To compress files", "To prevent data loss", "To protect data by encoding it", "To speed up data transmission"],
      answer: "To protect data by encoding it"
    },
    // ...add more questions up to 30 for each subject as needed
  ];
  
  export default questions;
  