import React, { useState } from "react";
import html2canvas from "html2canvas";
import { jsPDF } from "jspdf";
import { QRCodeCanvas } from "qrcode.react";
import logo from "../../assets/m10.jpg";
import aicte_logo_new from "../../assets/aicte_logo_new.png";
import msme_logo from "../../assets/msme_logo.png";
import raghvendra_signature from "../../assets/raghvendra_signature.png";

const CertificatePage = ({ internName, instituteName, examName, startDate, endDate, score, totalQuestions, id }) => {
  const [loading, setLoading] = useState(false);

  const generatePDF = () => {
    setLoading(true);
    const downloadButton = document.getElementById("download-button");
    downloadButton.style.display = "none";  // Hide button

    const input = document.getElementById("certificate");
    html2canvas(input).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF("p", "mm", "a4");
      pdf.addImage(imgData, "PNG", 10, 10, 190, 270);
      pdf.save("Certificate.pdf");

      downloadButton.style.display = "block"; // Show button again
      setLoading(false);
    });
  };

  return (
    <div
      id="certificate"
      style={{
        width: "850px",
        padding: "40px",
        border: "8px solid #0d47a1",
        backgroundColor: "#f9f9f9",
        boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.2)",
        margin: "30px auto",
        textAlign: "center",
        fontFamily: "Georgia, serif",
      }}
    >
      <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
        <img src={logo} alt="Codevocado Logo" style={{ height: "70px" }} />
        <img src={aicte_logo_new} alt="AICTE Logo" style={{ height: "70px" }} />
        <img src={msme_logo} alt="MSME Logo" style={{ height: "70px" }} />
      </div>
      <h1 style={{ color: "#0d47a1", fontSize: "36px", fontWeight: "bold", marginTop: "20px" }}>Certificate of Completion</h1>
      <p style={{ fontSize: "18px", color: "#333" }}>This is to certify that</p>
      <h2 style={{ fontSize: "28px", fontWeight: "bold", color: "#283593", margin: "10px 0" }}>{internName}</h2>
      <p style={{ fontSize: "18px", color: "#333" }}>from</p>
      <h3 style={{ fontSize: "24px", fontWeight: "bold", color: "#283593", margin: "10px 0" }}>{instituteName}</h3>
      <p style={{ fontSize: "18px", color: "#333" }}>
        has successfully completed a 10-week internship in
      </p>
      <h3 style={{ fontSize: "24px", fontWeight: "bold", color: "#283593", margin: "10px 0" }}>{examName}</h3>
      <p style={{ fontSize: "18px", color: "#333" }}>from {startDate} to {endDate}</p>
      <p style={{ fontSize: "18px", color: "#333" }}>with a score of</p>
      <h2 style={{ fontSize: "26px", fontWeight: "bold", color: "#ff5722" }}>{score} / {totalQuestions}</h2>

      <div style={{ display: "flex", justifyContent: "space-around", marginTop: "40px" }}>
        <div style={{ textAlign: "center" }}>
          <img src={raghvendra_signature} alt="Founder Signature" style={{ height: "80px" }} />
          <p style={{ margin: "5px 0 0", color: "#333" }}>Raghvendra Upadhyay</p>
          <p style={{ color: "#666" }}>Founder, Codevocado</p>
        </div>
        <div style={{ textAlign: "center", marginTop: "20px" }}>
          <hr style={{ width: "120px", border: "1px solid #0d47a1" }} />
          <p style={{ color: "#333" }}>Authorized Signature</p>
        </div>
      </div>

      <div style={{ marginTop: "40px", textAlign: "center" }}>
        <p style={{ fontSize: "16px", color: "#333" }}>
          Verify this certificate at <a href="https://codevocado.in/certificate/verify">www.codevocado.in/certificate/verify</a>
        </p>
        <QRCodeCanvas
          value={`https://codevocado.in/certificate/verify/${id}`}
          size={80}
          style={{ marginTop: "10px" }}
        />
      </div>

      <button
        id="download-button"
        onClick={generatePDF}
        style={{
          backgroundColor: "#0d47a1",
          color: "#fff",
          padding: "10px 30px",
          border: "none",
          borderRadius: "5px",
          cursor: "pointer",
          fontSize: "16px",
          marginTop: "30px",
        }}
      >
        {loading ? "Generating..." : "Download Certificate"}
      </button>
    </div>
  );
};

export default CertificatePage;
