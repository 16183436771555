import React, { useState } from 'react';
import './Modal.css'; // Ensure this is the correct path

function Modal({ product, closeModal }) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    mobile: '',
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission logic here
    console.log('Form data submitted:', formData);
    closeModal();
  };

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={closeModal}>X</button>
        <div className="modal-body">
          <div className="modal-left">
            <img src={product.image} alt={product.name} className="modal-product-image" />
          </div>
          <div className="modal-right">
            <h2 className="modal-title">Purchase {product.name}</h2>
            <p className="modal-description">{product.description}</p>
            <p className="modal-price">{product.price}</p>
            <form className="purchase-form" onSubmit={handleSubmit}>
              <label>
                Name:
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                  className="form-input"
                />
              </label>
              <label>
                Email:
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                  className="form-input"
                />
              </label>
              <label>
                Mobile:
                <input
                  type="tel"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange}
                  required
                  pattern="[0-9]{10}"
                  placeholder="10-digit number"
                  className="form-input"
                />
              </label>
              <button type="submit" className="submit-button">Submit</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Modal;
