import React, { useState, useEffect } from 'react';
import { FaLaptopCode, FaBullhorn, FaUsers, FaChartLine, FaCertificate, FaHandshake, FaClipboardList, FaArrowRight } from 'react-icons/fa';
import certificateImage from '../assets/certificate.png'; // Import your certificate image

const styles = {
  careerPage: {
    padding: '20px',
    fontFamily: 'Arial, sans-serif',
    textAlign: 'center',
    backgroundColor: '#f4f4f4',
  },
  title: {
    fontSize: '2em',
  },
  description: {
    fontSize: '1.2em',
    color: '#7f8c8d',
    marginBottom: '30px',
  },
  positions: {
    display: 'flex',
    justifyContent: 'space-around',
    flexWrap: 'wrap',
    gap: '20px',
    alignItems: 'center',
    margin: '20px 0',
  },
  position: {
    width: '100%',
    maxWidth: '300px',
    marginBottom: '20px',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    transition: 'transform 0.3s ease',
    textAlign: 'center',
  },
  positionHover: {
    transform: 'scale(1.05)',
  },
  positionTitle: {
    fontSize: '1.5em',
    margin: '10px 0',
  },
  positionDescription: {
    fontSize: '1em',
    color: '#7f8c8d',
    margin: '10px 0',
  },
  applyButton: {
    padding: '10px 20px',
    fontSize: '1em',
    fontWeight: 'bold',
    backgroundColor: '#3498db',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  applyButtonHover: {
    backgroundColor: '#2980b9',
  },
  benefits: {
    marginTop: '30px',
  },
  benefitsList: {
    listStyle: 'none',
    padding: '0',
    fontSize: '1em',
    lineHeight: '1.6em',
  },
  benefitItem: {
    margin: '10px 0',
  },
  benefitIcon: {
    marginRight: '10px',
  },
  applicationProcess: {
    marginTop: '30px',
  },
  applicationProcessTitle: {
    fontSize: '1.5em',
    marginBottom: '10px',
  },
  applicationProcessDescription: {
    fontSize: '1em',
    color: '#7f8c8d',
  },
  certificateImage: {
    width: '90%',
    height: 'auto',
    maxWidth: '750px',
    marginTop: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
  },
  modal: {
    position: 'fixed',
    top: '0',
    left: '0',
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  modalContent: {
    backgroundColor: '#fff',
    padding: '20px',
    borderRadius: '10px',
    width: '90%',
    maxWidth: '500px',
    position: 'relative',
  },
  closeButton: {
    position: 'absolute',
    top: '10px',
    right: '10px',
    background: 'none',
    border: 'none',
    fontSize: '1.5em',
    cursor: 'pointer',
  },
  formContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  formGroup: {
    marginBottom: '15px',
  },
  label: {
    display: 'block',
    fontSize: '1em',
    marginBottom: '5px',
  },
  input: {
    width: '100%',
    padding: '10px',
    fontSize: '1em',
    borderRadius: '5px',
    border: '1px solid #ddd',
  },
  fileInput: {
    width: '100%',
    padding: '10px',
    fontSize: '1em',
    borderRadius: '5px',
    border: '1px solid #ddd',
  },
  submitButton: {
    padding: '10px 20px',
    fontSize: '1em',
    fontWeight: 'bold',
    backgroundColor: '#3498db',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
    transition: 'background-color 0.3s ease',
  },
  submitButtonHover: {
    backgroundColor: '#2980b9',
  },
  '@media only screen and (max-width: 768px)': {
    careerPage: {
      padding: '15px',
    },
    positions: {
      flexDirection: 'column',
      alignItems: 'center',
      gap: '15px',
    },
    applyButton: {
      width: '80%',
    },
  },
  benefits: {
    marginTop: '30px',
  },
  benefitsList: {
    listStyle: 'none',
    padding: '0',
    fontSize: '1em',
    lineHeight: '1.6em',
    textAlign: 'left', // Adjusted for better alignment
    margin: '0 auto', // Center align the list
    maxWidth: '600px', // Limit the width for better readability
  },
  benefitItem: {
    margin: '10px 0',
    display: 'flex',
    alignItems: 'center', // Align icons and text
  },
  benefitIcon: {
    marginRight: '10px',
    fontSize: '1.5em', // Adjust size as needed
    color: '#3498db', // Match the button color for consistency
  },
};

const Position = ({ icon: Icon, title, description, onApplyClick }) => {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      style={{
        ...styles.position,
        ...(isHovered && styles.positionHover),
      }}
      onMouseOver={() => setIsHovered(true)}
      onMouseOut={() => setIsHovered(false)}
    >
      <Icon style={styles.icon} />
      <h2 style={styles.positionTitle}>{title}</h2>
      <p style={styles.positionDescription}>{description}</p>
      <button
        style={{
          ...styles.applyButton,
          ...(isHovered && styles.applyButtonHover),
        }}
        onClick={onApplyClick}
      >
        Apply Now <FaArrowRight style={{ marginLeft: '10px' }} />
      </button>
    </div>
  );
};

function CareerPage() {
  const [showModal, setShowModal] = useState(false);

  const handleApplyClick = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    if (showModal) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }
  }, [showModal]);

  return (
    <div style={styles.careerPage}>
      <h1 style={styles.title}>Join Our Team at Codevocado</h1>
      <p style={styles.description}>
        At Codevocado, we are looking for talented individuals to join our dynamic team.
        Explore our open positions below and apply to be part of our innovative journey!
      </p>

      <div style={styles.positions}>
        <Position
          icon={FaLaptopCode}
          title="Software Engineer"
          description="Build and optimize web applications, collaborate with cross-functional teams, and contribute to a high-performing engineering team."
          onApplyClick={handleApplyClick}
        />
        <Position
          icon={FaBullhorn}
          title="Marketing Specialist"
          description="Drive our marketing efforts, develop engaging campaigns, and analyze market trends to enhance our brand presence."
          onApplyClick={handleApplyClick}
        />
        <Position
          icon={FaUsers}
          title="HR Manager"
          description="Lead our HR initiatives, manage talent acquisition, employee relations, and foster a positive work culture."
          onApplyClick={handleApplyClick}
        />
        <Position
          icon={FaChartLine}
          title="Data Analyst"
          description="Analyze data to uncover insights, support decision-making processes, and drive business growth with data-driven strategies."
          onApplyClick={handleApplyClick}
        />
      </div>

      <div style={styles.benefits}>
        <h2 style={styles.title}>Why Join Us?</h2>
        <ul style={styles.benefitsList}>
          <li style={styles.benefitItem}><FaCertificate style={styles.benefitIcon} />Competitive salary packages</li>
          <li style={styles.benefitItem}><FaHandshake style={styles.benefitIcon} />Collaborative and inclusive work environment</li>
          <li style={styles.benefitItem}><FaClipboardList style={styles.benefitIcon} />Comprehensive health and wellness benefits</li>
          <li style={styles.benefitItem}><FaChartLine style={styles.benefitIcon} />Professional development opportunities</li>
          <li style={styles.benefitItem}><FaChartLine style={styles.benefitIcon} />Letter of Recommendation</li>
          <li style={styles.benefitItem}><FaChartLine style={styles.benefitIcon} />Earn a Certificate</li>
        </ul>
      </div>

      <div style={styles.applicationProcess}>
        <h2 style={styles.applicationProcessTitle}>Our Application Process</h2>
        <p style={styles.applicationProcessDescription}>
          Once you submit your application, our hiring team will review your profile. If you are shortlisted, we will reach out to schedule an interview.
        </p>
      </div>

      <img src={certificateImage} alt="Certificate" style={styles.certificateImage} />

      {showModal && (
        <div style={styles.modal}>
          <div style={styles.modalContent}>
            <button
              style={styles.closeButton}
              onClick={handleCloseModal}
            >
              &times;
            </button>
            <h2 style={styles.title}>Apply for a Position</h2>
            <form style={styles.formContent}>
              <div style={styles.formGroup}>
                <label style={styles.label} htmlFor="fullName">Full Name</label>
                <input style={styles.input} type="text" id="fullName" name="fullName" />
              </div>
              <div style={styles.formGroup}>
                <label style={styles.label} htmlFor="email">Email Address</label>
                <input style={styles.input} type="email" id="email" name="email" />
              </div>
              <div style={styles.formGroup}>
                <label style={styles.label} htmlFor="resume">Upload Resume</label>
                <input style={styles.fileInput} type="file" id="resume" name="resume" />
              </div>
              <button
                style={styles.submitButton}
                type="submit"
              >
                Submit Application
              </button>
            </form>
          </div>
        </div>
      )}
    </div>
  );
}

export default CareerPage;
