import React from 'react';
import styled from 'styled-components';

const PrivacyWrapper = styled.div`
  padding: 20px;
  max-width: 1200px;
  margin: 0 auto;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;
  background-color: #f9f9f9;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
`;

const Section = styled.section`
  margin-bottom: 20px;
`;

const Title = styled.h1`
  text-align: center;
  font-size: 2rem;
  margin-bottom: 20px;
  color: #007bff;
`;

const Subtitle = styled.h2`
  font-size: 1.5rem;
  margin-bottom: 15px;
  color: #007bff;
`;

const Paragraph = styled.p`
  margin-bottom: 15px;
  text-align: justify;
`;

const List = styled.ul`
  list-style-type: disc;
  padding-left: 40px;
  margin-bottom: 20px;
`;

const ListItem = styled.li`
  margin-bottom: 10px;
`;

const BackToTop = styled.a`
  display: inline-block;
  margin-top: 20px;
  text-decoration: none;
  color: #007bff;
  font-weight: bold;

  &:hover {
    text-decoration: underline;
    color: #0056b3;
  }
`;

const PrivacyPolicy = () => {
  return (
    <PrivacyWrapper>
      <Title>Privacy Policy</Title>

      <Section>
        <Subtitle>Introduction</Subtitle>
        <Paragraph>
          This Privacy Policy describes how Raghvendra Upadhyay and its affiliates (collectively "
          <strong>Raghvendra Upadhyay, we, our, us</strong>") collect, use, share, protect or otherwise
          process your information/personal data through our website{" "}
          <a href="https://codevocado.in" target="_blank" rel="noopener noreferrer">
            https://codevocado.in
          </a>{" "}
          (hereinafter referred to as the <strong>Platform</strong>). Please note that you may browse
          certain sections of the Platform without registering with us.
        </Paragraph>
      </Section>

      <Section>
        <Subtitle>Collection of Data</Subtitle>
        <Paragraph>
          We collect your personal data when you use our Platform, services, or otherwise interact with
          us during the course of our relationship. Some of the information we may collect includes, but
          is not limited to:
        </Paragraph>
        <List>
          <ListItem>Personal details like name, date of birth, address, phone number, and email ID.</ListItem>
          <ListItem>Sensitive data with your consent, such as payment information or biometric data.</ListItem>
          <ListItem>Behavioral data such as preferences and browsing patterns on our Platform.</ListItem>
        </List>
      </Section>

      <Section>
        <Subtitle>Usage of Data</Subtitle>
        <Paragraph>
          We use your data to provide services you request, enhance your experience, and market to you.
          Specifically, your data is used for:
        </Paragraph>
        <List>
          <ListItem>Processing orders and payments.</ListItem>
          <ListItem>Customizing your experience on our Platform.</ListItem>
          <ListItem>Fraud detection and resolution of disputes.</ListItem>
        </List>
      </Section>

      <Section>
        <Subtitle>Sharing of Data</Subtitle>
        <Paragraph>
          We may share your data with third parties such as business partners, payment processors, or
          law enforcement agencies if required. Your data is shared only for legitimate purposes, as per
          applicable laws.
        </Paragraph>
      </Section>

      <Section>
        <Subtitle>No Refund Policy</Subtitle>
        <Paragraph>
          All purchases and payments made on our Platform are final and non-refundable. Once a service or
          product is purchased, it cannot be returned, canceled, or refunded, unless explicitly stated
          otherwise in the terms of the specific service. By making a purchase, you acknowledge and agree
          to this No Refund Policy. For any clarifications, feel free to contact us at{" "}
          <a href="mailto:info@codevocado.in" target="_blank" rel="noopener noreferrer">
            info@codevocado.in
          </a>
          .
        </Paragraph>
      </Section>

      <Section>
        <Subtitle>Security Measures</Subtitle>
        <Paragraph>
          We adopt reasonable security practices to protect your personal data from unauthorized access
          or misuse. However, data transmission over the internet may have inherent risks, and users are
          advised to safeguard their login credentials.
        </Paragraph>
      </Section>

      <Section>
        <Subtitle>Data Deletion and Retention</Subtitle>
        <Paragraph>
          You may request to delete your account via the Platform. However, we may retain anonymized
          data for research and fraud prevention purposes, as permitted by applicable law.
        </Paragraph>
      </Section>

      <Section>
        <Subtitle>Your Rights</Subtitle>
        <Paragraph>
          You have the right to access, update, or delete your personal data. Contact us for any
          assistance at:{" "}
          <a href="mailto:info@codevocado.in" target="_blank" rel="noopener noreferrer">
            info@codevocado.in
          </a>
          .
        </Paragraph>
      </Section>

      <Section>
        <Subtitle>Changes to This Policy</Subtitle>
        <Paragraph>
          We may update this Privacy Policy from time to time. Please review it periodically to stay
          informed about how we handle your data.
        </Paragraph>
      </Section>

      <BackToTop href="#top">Back to Top</BackToTop>
    </PrivacyWrapper>
  );
};

export default PrivacyPolicy;
