import React from "react";
import "./TermsAndConditions.css";

const TermsAndConditions = () => {
  return (
    <div className="terms-container">
      <h1>Terms & Conditions</h1>
      <p>
        This document is an electronic record in terms of the Information
        Technology Act, 2000, and rules thereunder as applicable and the
        amended provisions pertaining to electronic records in various statutes
        as amended by the Information Technology Act, 2000. This electronic
        record is generated by a computer system and does not require any
        physical or digital signatures.
      </p>
      <p>
        This document is published in accordance with the provisions of Rule 3
        (1) of the Information Technology (Intermediaries guidelines) Rules,
        2011 that require publishing the rules and regulations, privacy policy,
        and Terms of Use for access or usage of the domain name{" "}
        <a href="https://codevocado.in">https://codevocado.in</a> ("Website"),
        including the related mobile site and mobile application (hereinafter
        referred to as "Platform").
      </p>
      <p>
        The Platform is owned by RAGHVENDRA UPADHYAY, a company incorporated
        under the Companies Act, 1956 with its registered office at 18 Deubari,
        Deoria, DEORIA, UTTAR PRADESH, 274604 (hereinafter referred to as
        "Platform Owner", "we", "us", "our").
      </p>
      <p>
        Your use of the Platform and services and tools are governed by the
        following terms and conditions (“Terms of Use”) as applicable to the
        Platform, including the applicable policies incorporated herein by way
        of reference. By mere use of the Platform, you shall be contracting
        with the Platform Owner, and these terms and conditions, including the
        policies, constitute your binding obligations.
      </p>
      <h2>Terms of Use</h2>
      <ol>
        <li>
          To access and use the Services, you agree to provide true, accurate,
          and complete information to us during and after registration and are
          responsible for all acts done through your registered account on the
          Platform.
        </li>
        <li>
          Neither we nor any third parties provide any warranty or guarantee as
          to the accuracy, timeliness, performance, completeness, or suitability
          of the information and materials offered on this website or through
          the Services for any specific purpose.
        </li>
        <li>
          Your use of our Services and the Platform is solely and entirely at
          your own risk and discretion, for which we shall not be liable in any
          manner.
        </li>
        <li>
          The contents of the Platform and Services are proprietary and
          licensed to us. Unauthorized use may lead to action against you.
        </li>
        <li>
          You agree to pay us the charges associated with availing of the
          Services.
        </li>
        <li>
          You agree not to use the Platform and/or Services for any unlawful or
          illegal purposes.
        </li>
        <li>
          You acknowledge that the Platform may contain links to third-party
          websites. You will be governed by their terms and policies upon
          accessing those links.
        </li>
        <li>
          By initiating a transaction, you are entering into a legally binding
          contract with the Platform Owner.
        </li>
        <li>
          You shall indemnify and hold harmless the Platform Owner and its
          affiliates from any claims arising from your breach of these Terms.
        </li>
        <li>
          The Platform Owner shall not be liable for failure to perform due to
          force majeure events.
        </li>
        <li>
          These Terms and disputes relating to them shall be governed by the
          laws of India.
        </li>
        <li>
          All disputes shall be subject to the exclusive jurisdiction of the
          courts in Deoria, Uttar Pradesh.
        </li>
      </ol>
      <p>
        All concerns or communications relating to these Terms must be
        communicated to us using the contact information provided on this
        website.
      </p>
    </div>
  );
};

export default TermsAndConditions;
