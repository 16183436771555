import React from 'react';
import styled, { keyframes } from 'styled-components';
import { FaFacebook, FaTwitter, FaLinkedin } from 'react-icons/fa';
import footerImage from '../assets/foter.jpg';
import footer2 from '../assets/l213.jpg';
import { Link } from "react-router-dom";

const slideUp = keyframes`
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
`;

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const FooterWrapper = styled.footer`
  background-color: #333;
  color: #fff;
  padding: 40px 20px;
  animation: ${slideUp} 0.6s ease-out;
`;

const FooterTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const FooterSection = styled.div`
  flex: 1;
  margin-bottom: 20px;
  animation: ${fadeIn} 0.6s ease-out;
  min-width: 200px;
`;

const FooterLinksList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const FooterLink = styled.a`
  color: #fff;
  text-decoration: none;
  transition: color 0.3s ease-out;

  &:hover {
    text-decoration: underline;
    color: #007bff;
  }
`;

const PlainText = styled.span`
  color: #fff;
  display: inline-block;
  margin-bottom: 10px;
`;

const SocialIcons = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const SocialIcon = styled.a`
  color: #fff;
  font-size: 20px;
  margin-bottom: 10px;
  text-decoration: none;
  transition: color 0.3s ease-out;

  &:hover {
    color: #007bff;
  }
`;

const FooterBottom = styled.div`
  text-align: center;
  margin-top: 20px;
  padding-top: 20px;
  border-top: 1px solid #666;
  animation: ${slideUp} 0.6s ease-out;
`;

const CareerSection = styled.div`
  flex: 1;
  margin-bottom: 20px;
  animation: ${fadeIn} 0.6s ease-out;
`;

const FooterImage = styled.img`
  width: 80px;
  height: 80px;
  border-radius: 50%;
  object-fit: cover;
  margin: 10px;
`;

const Footer = () => {
  return (
    <FooterWrapper>
      <FooterTop>
        <FooterSection>
          <h3>Company Info</h3>
          <p><PlainText>About Us</PlainText></p>
          <p><PlainText>Contact Us</PlainText></p>
          {/* <p><FooterLink href="terms-and-conditions">Terms and Conditions</FooterLink></p>
          <p><FooterLink href="privacy">Privacy Policy</FooterLink></p> */}

<p><Link to="/terms-and-conditions">Terms and Conditions</Link></p>
<p><Link to="/privacy">Privacy Policy</Link></p>
        </FooterSection>
        <FooterSection>
          <h3>Services</h3>
          <FooterLinksList>
            <li><PlainText>Web Development</PlainText></li>
            <li><PlainText>Cybersecurity</PlainText></li>
            <li><PlainText>Database Management</PlainText></li>
            <li><PlainText>Custom Software</PlainText></li>
            <li><PlainText>Mobile App Development</PlainText></li>
            <li><PlainText>AI Solutions</PlainText></li>
            <li><PlainText>Cloud Services</PlainText></li>
          </FooterLinksList>
        </FooterSection>
        <CareerSection>
          <h3>Career</h3>
          <PlainText>Software Full Stack Developer Intern</PlainText>
          <PlainText>Marketing and Sales Intern</PlainText>
          <PlainText>HR Intern</PlainText>
          <PlainText>Business Development Intern</PlainText>
        </CareerSection>
        <FooterSection>
          <h3>Address</h3>
          <p>Upadhyayinfotech,</p>
          <p>18 Deubari Deoria, UP, 274604</p>
          <p>Phone: +91-0556167890</p>
          <p>Email: <FooterLink href="mailto:info@codevocado.in">info@codevocado.in</FooterLink></p>
        </FooterSection>
        <FooterSection>
          <h3>Follow Us</h3>
          <SocialIcons>
            <SocialIcon href="https://www.facebook.com/codevocado" target="_blank" rel="noopener noreferrer"><FaFacebook /></SocialIcon>
            <SocialIcon href="https://twitter.com/codevocado" target="_blank" rel="noopener noreferrer"><FaTwitter /></SocialIcon>
            <SocialIcon href="https://www.linkedin.com/company/codevocado" target="_blank" rel="noopener noreferrer"><FaLinkedin /></SocialIcon>
          </SocialIcons>
        </FooterSection>
      </FooterTop>
      <FooterBottom>
        <p>&copy; 2024 codevocado. All rights reserved.</p>
        <FooterImage src={footerImage} alt="Footer Image 1" />
        <FooterImage src={footer2} alt="Footer Image 2" />
      </FooterBottom>
    </FooterWrapper>
  );
};

export default Footer;
