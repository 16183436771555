import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'; // Ensure proper imports
import './App.css';
import Header from './components/Header';
import AboutUs from './components/AboutUs';
import Services from './components/Services';
import ValueAddition from './components/ValueAddition';
import Offer from './components/Offer';
import Footer from './components/Footer';
import Inquiry from './components/Inquiry';
// import whatsappImg from './assets/whatsapp.png'; // Import the image
import Chatbot from './components/Chatbot';
import Scroll from './components/Scroll';
import Clients from './components/Clients';
import Freetrialsection from './components/Freetrialsection';
import GoogleMap from './components/Googlemap';
import CareerPage from './components/Career';
import Store from './components/Store';
import CoursePage from './components/CoursePage';
import ExamPage from './components/Exam/ExamPage';
import CertificatePage from './components/Exam/CertificatePage';
import TermsAndConditions from './components/TermsAndConditions';
import PrivacyPolicy from './components/PrivacyPolicy';
function App() {
  return (
    <Router>
      <div className="App">
        

        <Routes>
          <Route path="/" element={
            
            <>
            <Header />
              <div id="services">
                <Services />
              </div>
              <div id="inquiry">
                <Inquiry />
              </div>
              <div id="aboutus">
                <AboutUs />
              </div>
              <div id="career">
                <CareerPage />
              </div>
              <ValueAddition />
              <Offer />
              <Clients />
              <Freetrialsection />
              <GoogleMap />
            </>
          } />
          
          <Route path="/codevocado" element={
            
            <>
            <Header />
              <div id="services">
                <Services />
              </div>
              <div id="inquiry">
                <Inquiry />
              </div>
              <div id="aboutus">
                <AboutUs />
              </div>
              <div id="career">
                <CareerPage />
              </div>
              <ValueAddition />
              <Offer />
              <Clients />
              <Freetrialsection />
              <GoogleMap />
            </>
          } />

          <Route path="/store" element={<Store />} />
          <Route path="/test" element={<CoursePage />} />
          <Route path="/take-test" element={<ExamPage />} />
          <Route path="/CertificatePage " element={<CertificatePage />} />
          <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
          <Route path="/privacy" element={<PrivacyPolicy />} />
          </Routes>

        <Footer />

        {/* WhatsApp Chat Button */}
        {/* <a 
          href="https://wa.me/9005217129?text=Hello%20I%20need%20help" 
          target="_blank" 
          rel="noopener noreferrer" 
          className="whatsapp-button"
        >
          <img src={whatsappImg} alt="Chat with us on WhatsApp"/>
        </a> */}

        {/* Chatbot Button */}
        <Chatbot />

        {/* Scroll Button */}
        <Scroll />
      </div>
    </Router>
  );
}

export default App;
