import React, { useState, useEffect } from "react";
import questions from "./questions";
import CertificatePage from "./CertificatePage";

const ExamPage = () => {
  const [selectedAnswers, setSelectedAnswers] = useState({});
  const [submitted, setSubmitted] = useState(false);
  const [score, setScore] = useState(0);
  const [examName, setExamName] = useState("PHP");

  useEffect(() => {
    const exam = sessionStorage.getItem("examName") || "PHP";
    setExamName(exam);
  }, []);

  const filteredQuestions = questions.filter((q) => q.subject === examName);

  const handleAnswerChange = (questionId, answer) => {
    setSelectedAnswers({
      ...selectedAnswers,
      [questionId]: answer,
    });
  };

  const handleSubmit = () => {
    let totalScore = 0;
    filteredQuestions.forEach((question) => {
      if (selectedAnswers[question.id] === question.answer) {
        totalScore += 1;
      }
    });
    setScore(totalScore);
    setSubmitted(true);
  };

  return (
    <div style={{ padding: "20px", maxWidth: "800px", margin: "auto", fontFamily: "Arial, sans-serif" }}>
      {!submitted ? (
        <>
          <h1 style={{ color: "#4a90e2", textAlign: "center" }}>{examName} Exam</h1>
          {filteredQuestions.map((question) => (
            <div key={question.id} style={{ marginBottom: "20px", padding: "15px", border: "1px solid #ddd", borderRadius: "8px" }}>
              <h3 style={{ color: "#333" }}>{question.question}</h3>
              {question.options.map((option) => (
                <label key={option} style={{ display: "block", marginBottom: "5px" }}>
                  <input
                    type="radio"
                    name={`question-${question.id}`}
                    value={option}
                    checked={selectedAnswers[question.id] === option}
                    onChange={() => handleAnswerChange(question.id, option)}
                    style={{ marginRight: "8px" }}
                  />
                  {option}
                </label>
              ))}
            </div>
          ))}
          <button
            onClick={handleSubmit}
            style={{
              backgroundColor: "#4a90e2",
              color: "#fff",
              padding: "10px 20px",
              border: "none",
              borderRadius: "5px",
              cursor: "pointer",
              fontSize: "16px",
              display: "block",
              margin: "20px auto",
            }}
          >
            Submit
          </button>
        </>
      ) : (
        <CertificatePage
          internName={sessionStorage.getItem("internName") || "Intern's Full Name"}
          instituteName={sessionStorage.getItem("instituteName") || "Institute Name"}
          examName={examName}
          startDate={sessionStorage.getItem("startDate") || "[Start Date]"}
          endDate={sessionStorage.getItem("endDate") || "[End Date]"}
          score={score}
          totalQuestions={filteredQuestions.length}
        />
      )}
    </div>
  );
};

export default ExamPage;
